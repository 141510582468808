export interface IState {
	items: IMenuFirstItem[]
}

const deafultState: IState = {
	items: []
}
export interface IMenuFirstItem {
	title: string,
	uri: string,
	children: IMenuItem[],
	show: boolean,
	id: string,
	active: boolean,
	childActive: boolean,
}
export interface IMenuItem {
	title: string,
	uri: string
}


export default deafultState;
