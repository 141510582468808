




import Vue from 'vue'
import MainMenu from './components/MainMenu.vue';
import { IMenuFirstItem } from './store/state';

// Interfaces for component typechecking!
interface AppData {

}

interface AppMethods {

}

interface AppComputed {
	items: IMenuFirstItem[]
}

interface AppProps {

}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
	name: 'app',
	computed: {
		items(){
			return this.$store.getters.items;
		}
	},
	components: {
		MainMenu
	}
})
