




















































import Vue from "vue";
import { IMenuItem, IMenuFirstItem } from "../store/state";
// import focusOutside from "vue-focus-outside";

interface MainMenuProps {
  items: IMenuFirstItem[];
}
interface MainMenuData {}
interface MainMenuMethods {
  showSubItems(item: IMenuFirstItem): void;
  hideSubItemsOnTab(item: IMenuFirstItem, child: IMenuItem): void;
  hideSubItemsOnShiftTab(item: IMenuFirstItem, child: IMenuItem): void;
  toggleItems(item: IMenuFirstItem): void;
  handleOut(item: IMenuFirstItem): void;
  // focusOustsideHandler(item: IMenuFirstItem): void;
  // setSelected(itemUri: string, index: number): void;
}

export default Vue.extend<MainMenuData, MainMenuMethods, {}, MainMenuProps>({
  name: "MainMenu",
  data() {
    return {
      selectedChildUri: "",
      selectedChildIndex: 0,
    };
  },
  props: {
    items: Array,
  },
  methods: {
    showSubItems(item) {
      item.show = true;
    },
    handleOut(item) {
      console.log("xxxx");
      item.show = false;
    },
    toggleItems(item) {
      item.show = !item.show;
      	this.items.forEach(i => {
				if(i.id !== item.id) i.show = false
			})

    },
    // Item hide on shifttab
    hideSubItemsOnShiftTab(item, child) {
    if(child.uri === item.children[0].uri && item.show == true) {
        item.show = false
      }
    },
    // Item show on tab exact
    hideSubItemsOnTab(item, child) {
      if(child.uri === item.children[item.children.length - 1].uri && item.show == true) {
        item.show = false
      }
    },
  },
});
